import React, { useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { BrandEmail } from "./components/BrandEmail";
import { BrandProductTypes } from "./components/BrandProductTypes";
import { Ingredients } from "./components/Ingredients";

const csvUploadersMap = {
  BrandEmail: BrandEmail,
  BrandProductTypes: BrandProductTypes,
  Ingredients: Ingredients
};

const CSVUploaders = () => {
  const [selectedUploader, setSelectedUploader] = useState("BrandEmail");

  const handleChange = (event) => {
    setSelectedUploader(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="row" position="relative" flex={1} m={2}>
      <Box width={"15%"}>
        <Typography variant="h4" gutterBottom>
        CSV Uploaders
        </Typography>
        <Select value={selectedUploader} onChange={handleChange}>
          <MenuItem value="BrandEmail">Brand Email</MenuItem>
          <MenuItem value="BrandProductTypes">Brand Product Types</MenuItem>
          <MenuItem value="Ingredients">Ingredients</MenuItem>
        </Select>
      </Box>
      <Box mt={2} width={"75%"}>
        {}
        {csvUploadersMap[selectedUploader]()}
      </Box>
    </Box>
  );
};

export default CSVUploaders;

