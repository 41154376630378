import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../utils";

export const useIngredients = () => {
  return useQuery({
    queryKey: ["ingredients"],
    queryFn: async () => {
      return makeApiRequest({
        endpoint: "/api/ingredient_glossary",
        method: "GET",
      });
    },
    keepPreviousData: true,
  });
};

export const useUpsertIngredientMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      return makeApiRequest({
        endpoint: "/api/ingredient_glossary",
        method: "POST",
        body: JSON.stringify(data),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "ingredients" });
      },
    },
  );
};

export const useDeleteIngredientMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id) => {
      return makeApiRequest({
        endpoint: `/api/ingredient_glossary/${id}`,
        method: "DELETE",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "ingredients" });
      },
    }
  );
};
