import React, { useState } from "react";
import { Box, Typography, Drawer, TextField, Button, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tabs, Tab } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useBrandAttributesQuery, useUpsertBrandAttributeMutation, useDeleteBrandAttributeMutation } from "./hooks";
import { useForm, Controller } from "react-hook-form";
import { MarkdownOnlyPreview } from "components/Content/ContentPreview";
import { SubcategorySelect } from "components/Vendor/SubcategorySelect";

const columns = [
  { field: "id", headerName: "ID", width: 60 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "type", headerName: "Type", width: 250 },
  { field: "icon", headerName: "Icon", width: 150, renderCell: (params) => <IconPreview icon={params.value} /> },
  { field: "description", headerName: "Description", width: 450 },
];

const IconPreview = ({ icon }) => {
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <Box>
      {isValidUrl(icon) && <img src={icon} alt="icon" style={{ width: 40, height: 40 }} />}
    </Box>
  );
};

export const BrandAttributeEditor = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPreviewTab, setIsPreviewTab] = useState(false);
  const { data, isLoading } = useBrandAttributesQuery();
  const upsertBrandAttribute = useUpsertBrandAttributeMutation();
  const deleteBrandAttribute = useDeleteBrandAttributeMutation();
  const { register, handleSubmit, setValue, control, formState: { errors }, watch } = useForm(
    { defaultValues: { name: "", type: "Sustainability Guidance", icon: "", description: "", labelIcon: "", labelColor: "" } }
  );

  const onSubmit = async (values) => {
    const id = selectedRow?.id;
    if (id) {
      values.id = id;
    }
    values.joroSubcategoryIds = selectedSubcategoryIds ?? [];
    await upsertBrandAttribute.mutate(values);
    handleClose();
  };

  const setFormValues = (values) => {
    setValue("name", values.name ?? "");
    setValue("type", values.type ?? "");
    setValue("icon", values.icon ?? "");
    setValue("description", values.description ?? "");
  };

  const handleRowClick = (row) => {
    setFormValues(row.row);
    setSelectedRow(row.row);
    setSelectedSubcategoryIds(row.row.JoroSubcategories.map((subcategory) => subcategory.id));
    setIsDrawerOpen(true);
  };

  const handleChangeSubcategories = (event) => {
    setSelectedSubcategoryIds(event.target.value);
  };

  const handleAddClick = () => {
    setFormValues({});
    setSelectedRow(null);
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    setIsDrawerOpen(false);
    setSelectedRow(null);
  };

  const handleDelete = async () => {
    await deleteBrandAttribute.mutate(selectedRow.id);
    handleClose();
  };

  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    await handleDelete();
    setIsDialogOpen(false);
  };

  return (
    <Box mt={2}>
      <Typography variant="h2">Brand Attributes</Typography>
      <Box my={4}>
        <Box mb={2}>
          <Button variant="contained" color="primary" onClick={handleAddClick} my={2}>Add Brand Attribute</Button>
        </Box>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          onRowClick={handleRowClick}
          components={{ Toolbar: GridToolbar }}
          loading={isLoading}
        />
      </Box>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleClose} PaperProps={{ style: { width: 800 } }}>
        <Box p={2} width={600}>
          <Typography variant="h3" py={3} >{selectedRow ? "Edit Brand Attribute" : "Add Brand Attribute"}</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={2}>
              <TextField
                label="Name"
                fullWidth
                {...register("name", { required: true })}
                error={!!errors.name}
                helperText={errors.name ? "Name is required" : ""}
              />
            </Box>
            <Box mb={2}>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    fullWidth
                    label="Type"
                    inputRef={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  >
                    <MenuItem value="Guidance - Positive">Guidance - Positive</MenuItem>
                    <MenuItem value="Guidance - Negative">Guidance - Negative</MenuItem>
                    <MenuItem value="Guidance - Neutral">Guidance - Neutral</MenuItem>
                  </Select>
                )}
              />
              {errors.type && <Typography color="error">Type is required</Typography>}
            </Box>
            <Box mb={2} display="flex" alignItems="center">
              <TextField
                label="Badge"
                fullWidth
                {...register("icon")}
              />
              <IconPreview icon={watch("icon")} />
            </Box>
            <Box m={2}>
              <Controller control={control} name="labelIcon" render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  fullWidth
                  label="Label Icon"
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                >
                  <MenuItem value="cancel">Cancel 🚫</MenuItem>
                  <MenuItem value="heartFilled">Heart 💙</MenuItem>
                  <MenuItem value="star">Star ★</MenuItem>
                </Select>
              )}
              />
            </Box>
            <Box m={2}>
              <Controller control={control} name="labelColor" render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  fullWidth
                  label="Label Color"
                  inputRef={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                >
                  <MenuItem value="#0000A0">Commons Blue 🔵</MenuItem>
                  <MenuItem value="#EB3323">Red 🔴</MenuItem>
                  <MenuItem value="#6A6860">Gray ◻️</MenuItem>
                </Select>
              )}
              />
            </Box>
            <Box mb={2}>
              <SubcategorySelect
                value={selectedSubcategoryIds}
                label={"Subcategories"}
                multiple={true}
                onChange={handleChangeSubcategories} />
            </Box>
            <Box mb={2}>
              <Typography variant="h4">Description Helpers</Typography>
              <Typography variant="body2">🙅 Do your best to avoid copy and pasting items - oftentimes they contain hidden formatting characters that will mess up how markdown works!</Typography>
              <Typography variant="body2">🧠 Use the following markdown to format the description:</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Bold text: </span>**bold**</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800, fontStyle: "italic" }}>Italicize text: </span>*italic*</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800, textDecoration: "underline" }}>Add a link:</span> [link](https://example.com)</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800, textDecoration: "underline" }}>Add an image:</span> ![image](https://example.com/image.jpg)</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Make a code block: </span>```code```</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Make a bulleted list: </span>- list item</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Make a numbered list: </span>1. numbered item</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Make an H1: </span># Heading 1</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Make an H2: </span>## Heading 2</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Add a brand card: </span>@brand 123</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Add a small card: </span>@smallCard iconName #000000 &quot;title&quot; &quot;description&quot;</Typography>
              <Typography variant="body2"><span style={{ fontWeight: 800 }}>Insert brand name: </span>This {"{{brand}}"} is Dangerous</Typography>
            </Box>
            <Box mb={2}>
              <Tabs>
                <Tab label="Description" onClick={() => setIsPreviewTab(false)}></Tab>
                <Tab label="Preview" onClick={() => setIsPreviewTab(true)} ></Tab>
              </Tabs>
              {!isPreviewTab && <TextField
                label="Description"
                fullWidth
                multiline
                rows={10}
                {...register("description")}
              />}
              {isPreviewTab &&
                <MarkdownOnlyPreview body={watch("description")} />}
            </Box>
            <Box mb={2}>
              <Button variant="contained" color="primary" fullWidth type="submit">Save</Button>
            </Box>
            <Box mb={2}>
              <Button variant="contained" color="secondary" fullWidth onClick={handleClose}>Cancel</Button>
            </Box>
            <Box mb={2}>
              {selectedRow && <Button variant="contained" color="warning" fullWidth onClick={handleDeleteClick}>Delete</Button>}
            </Box>
          </form>
        </Box>
      </Drawer>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this brand attribute?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleDialogConfirm} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
