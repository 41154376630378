import React, { useCallback, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useIngredients,
  useUpsertIngredientMutation,
  useDeleteIngredientMutation,
} from "./hooks";
import { useForm, Controller } from "react-hook-form";
import { SidebarModuleCheck } from "components/Vendor/SidebarModule";
import { ingredientGlossaryColumns } from "../../constants/ingredientGlossaryConstants";

export const IngredientGlossaryEditor = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data, isLoading } = useIngredients();
  const [possibleMatches, setPossibleMatches] = useState([]);
  const { mutateAsync: upsertIngredient, isLoading: isUpserting } =
    useUpsertIngredientMutation();
  const { mutateAsync: deleteIngredient, isLoading: isDeleting } =
    useDeleteIngredientMutation();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    async (values) => {
      const id = selectedRow?.id;
      if (id) {
        values.id = id;
      }
      if (possibleMatches.length > 0) {
        values.overrideMatchCheck = true;
      }
      try {
        await upsertIngredient(values);
        setPossibleMatches([]);
        handleClose();
      } catch (e) {
        if (!id && Array.isArray(e.data)) {
          setPossibleMatches(e.data);
          return null;
        }
      }
    },
    [possibleMatches, selectedRow?.id, upsertIngredient]
  );

  const setFormValues = (values) => {
    setValue("id", values.id ?? null);
    setValue("ingredient", values.ingredient ?? "");
    setValue("isCaseSensitive", values.isCaseSensitive ?? false);
    setValue("isParaben", values.isParaben ?? false);
    setValue("isPhthalates", values.isPhthalates ?? false);
    setValue("isPFAS", values.isPFAS ?? false);
    setValue("isPetrochemical", values.isPetrochemical ?? false);
    setValue("isPalmOil", values.isPalmOil ?? false);
    setValue("hasNoIssue", values.hasNoIssue ?? false);
    setValue("isAmbiguous", values.isAmbiguous ?? false);
    setValue(
      "mayBePetrochemicalDerived",
      values.mayBePetrochemicalDerived ?? false
    );
    setValue("mayBePalmOilDerived", values.mayBePalmOilDerived ?? false);
    setValue("isMicroplastics", values.isMicroplastics ?? false);
    setValue("isHarmfulSuncare", values.isHarmfulSuncare ?? false);
    setValue("hasOtherConcern", values.hasOtherConcern ?? false);
  };

  const handleRowClick = (row) => {
    setFormValues(row.row);
    setSelectedRow(row.row);
    setIsDrawerOpen(true);
  };

  const handleAddClick = () => {
    setFormValues({});
    setSelectedRow(null);
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    setPossibleMatches([]);
    setIsDrawerOpen(false);
    setSelectedRow(null);
  };

  const handleDelete = async () => {
    await deleteIngredient(selectedRow.id);
    handleClose();
  };

  const handleDeleteClick = () => {
    setPossibleMatches([]);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setPossibleMatches([]);
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    await handleDelete();
    setIsDialogOpen(false);
  };

  return (
    <Box mt={2}>
      <Typography variant="h2">Ingredient Glossary</Typography>
      <Box my={4} maxWidth={window.screen.width * 0.8}>
        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddClick}
            my={2}
          >
            Add Ingredient
          </Button>
        </Box>
        <DataGrid
          rows={data}
          columns={ingredientGlossaryColumns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          onRowClick={handleRowClick}
          components={{ Toolbar: GridToolbar }}
          loading={isLoading}
        />
      </Box>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleClose}
        PaperProps={{ style: { width: 800 } }}
      >
        <Box p={2} width={600}>
          <Typography variant="h3" py={3}>
            {selectedRow ? "Edit Ingredient" : "Add Ingredient"}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={2}>
              <TextField
                label="Ingredient"
                fullWidth
                {...register("ingredient", { required: true })}
                error={!!errors.ingredient}
                helperText={errors.ingredient ? "Ingredient is required" : ""}
              />
            </Box>

            {ingredientGlossaryColumns
              .filter((column) => !["id", "ingredient"].includes(column.field))
              .map((column) => (
                <Box m={2} key={column.field}>
                  <Controller
                    control={control}
                    name={column.field}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SidebarModuleCheck
                        label={column.headerName}
                        isChecked={value}
                        name={column.field}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    )}
                  />
                </Box>
              ))}

            {possibleMatches.length > 0 ? (
              <Box mb={2}>
                <Typography variant="h3" py={3}>
                  Here are similar ingredients:
                </Typography>
                <List>
                  {possibleMatches.map((match) => {
                    return (
                      <ListItem key={match.id}>
                        <ListItemText>
                          id: {match.id}; ingredient: {match.ingredient}
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
                <Typography>
                  Not what you&apos;re looking for? Press Save again to
                  continue.
                </Typography>
              </Box>
            ) : null}
            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                loading={isUpserting.toString()}
              >
                {isUpserting ? "Saving..." : "Save"}
              </Button>
            </Box>
            <Box mb={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
            <Box mb={2}>
              {selectedRow && (
                <Button
                  variant="contained"
                  color="warning"
                  fullWidth
                  onClick={handleDeleteClick}
                  loading={isDeleting.toString()}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Drawer>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this ingredient?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
