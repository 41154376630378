const VALID_VENDOR_SEARCH_JOB_NAMES = {
  START_VENDOR_REGEX_SEARCH: "startVendorRegexSearch",
};
const VALID_SITE_CRAWLER_JOB_NAMES = {
  START_CRAWLING_SITE: "startCrawlingSite",
  CHECK_CRAWL_STATUS: "checkCrawlStatus",
};

module.exports = {
  VALID_VENDOR_SEARCH_JOB_NAMES,
  VALID_SITE_CRAWLER_JOB_NAMES,
};
