import { Box, Button, TextField, Typography, Autocomplete, Chip } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useCreateCollection, useUpdateCollection } from "./hooks";
import { useProductTypeQuery } from "../ProductTypes/hooks";
import ChildCollectionModal from "./ChildCollectionModal";

const GenieCollectionsForm = React.memo(({ collection, onSave }) => {
  const [showForm, setShowForm] = useState(!!collection);
  const [formData, setFormData] = useState(
    collection || {
      displayName: "",
      slug: "",
      searchTerms: "",
      pageContent: "",
      productTypes: [],
      children: [],
    }
  );

  const createCollection = useCreateCollection();
  const updateCollection = useUpdateCollection();
  const { data: productTypesData } = useProductTypeQuery();
  const productTypes = useMemo(() => productTypesData?.productTypes || [], [productTypesData]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSave = useCallback((e) => {
    e.preventDefault();
    // Transform data to match the expected API format
    const formattedData = {
      ...formData,
      productTypeIds: formData.productTypes.map(pt =>
        typeof pt === "object" ? Number(pt.id) : Number(pt)
      ),
      // Format children collections for API request
      ParentGenieCollectionMeta: formData.children.map(child => {
        // Handle empty string vs null/undefined properly
        const childId = child.id === "" ? null : child.id ? Number(child.id) : null;
        const childGenieId = child.childGenieCollectionId === "" ? null :
          child.childGenieCollectionId ? Number(child.childGenieCollectionId) : null;

        return {
          id: childId,
          slug: child.slug || "",
          displayName: child.displayName || "",
          description: child.description || "",
          overline: child.overline || "",
          underline: child.underline || "",
          cta: child.cta || "",
          searchOptions: child.searchOptions || {},
          childGenieCollectionId: childGenieId,
          // Add ChildGenieCollection object needed by the controller
          ChildGenieCollection: {
            id: childGenieId
          }
        };
      })
    };

    if (collection) {
      updateCollection.mutate({ id: Number(collection.id), updates: formattedData }, {
        onSuccess: (updatedCollection) => onSave(updatedCollection.id),
      });
    } else {
      createCollection.mutate(formattedData, {
        onSuccess: (newCollection) => onSave(newCollection.id),
      });
    }
  }, [formData, collection, updateCollection, createCollection, onSave]);

  // Transform ParentGenieCollectionMeta into children array format
  const mapChildCollections = useCallback((collection) => {
    if (!collection) { return []; }

    const childrenArray = collection.ParentGenieCollectionMeta?.map(meta => {
      return {
        id: meta.id ? Number(meta.id) : null,
        slug: meta.slug || meta.ChildGenieCollection?.slug || "",
        displayName: meta.displayName || meta.ChildGenieCollection?.displayName || "",
        description: meta.description || "",
        overline: meta.overline || "",
        underline: meta.underline || "",
        cta: meta.cta || "",
        searchOptions: meta.searchOptions || {},
        childGenieCollectionId: meta.childGenieCollectionId
          ? Number(meta.childGenieCollectionId)
          : (meta.ChildGenieCollection?.id ? Number(meta.ChildGenieCollection.id) : null)
      };
    }) || [];

    return childrenArray;
  }, []);

  // Map selected product types
  const mapSelectedProductTypes = useCallback((collection) => {
    if (!collection || !collection.productTypes) { return []; }

    return collection.productTypes.map(pt => ({
      id: Number(pt.id),
      name: pt.name
    }));
  }, []);

  const handleCancel = useCallback(() => {
    if (!collection) {
      setShowForm(false);
    }
    collection && setFormData({
      ...collection,
      productTypes: mapSelectedProductTypes(collection),
      children: mapChildCollections(collection)
    });
    onSave(collection?.id);
  }, [collection, mapChildCollections, mapSelectedProductTypes, onSave]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingChild, setEditingChild] = useState(null);
  const [editingChildIndex, setEditingChildIndex] = useState(null);

  const handleAddChild = useCallback(() => {
    setEditingChild(null);
    setEditingChildIndex(null);
    setIsModalOpen(true);
  }, []);

  const handleEditChild = useCallback((child, index) => {
    setEditingChild({ ...child });
    setEditingChildIndex(index);
    setIsModalOpen(true);
  }, []);

  const handleSaveChild = useCallback((child) => {
    setFormData((prev) => {
      if (editingChildIndex !== null) {
        const newChildren = [...prev.children];
        newChildren[editingChildIndex] = { ...child };
        return {
          ...prev,
          children: newChildren
        };
      } else {
        return {
          ...prev,
          children: [...prev.children, { ...child }]
        };
      }
    });

    setIsModalOpen(false);
  }, [editingChildIndex]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setEditingChild(null);
    setEditingChildIndex(null);
  }, []);

  useEffect(() => {
    setShowForm(!!collection);
    if (collection) {
      setFormData({
        ...collection,
        productTypes: mapSelectedProductTypes(collection),
        children: mapChildCollections(collection)
      });
    } else {
      setFormData({
        displayName: "",
        slug: "",
        searchTerms: "",
        pageContent: "",
        productTypes: [],
        children: [],
      });
    }
  }, [collection, mapSelectedProductTypes, mapChildCollections]);

  if (!showForm && !collection) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", m: 4 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setShowForm(true)}
        >
          Create New Collection
        </Button>
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSave} sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 3, p: 3, bgcolor: "background.paper", borderRadius: 2, boxShadow: 3 }}>
      <Box>
        <Typography variant="h6">Details</Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Display Name"
          name="displayName"
          value={formData.displayName || ""}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Slug"
          name="slug"
          value={formData.slug || ""}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Page Content"
          name="pageContent"
          multiline
          rows={4}
          value={formData.pageContent || ""}
          onChange={handleChange}
        />
        <Box mt={3}>
          <Typography variant="h6">Primary?</Typography>
          <input
            type="checkbox"
            name="isPrimary"
            checked={formData.isPrimary || false}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                isPrimary: e.target.checked,
              }))
            }
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="h6">Criteria</Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Search Terms"
          name="searchTerms"
          value={formData.searchTerms || ""}
          onChange={handleChange}
        />
        <Autocomplete
          multiple
          options={productTypes}
          getOptionLabel={(option) => typeof option === "object" ? option.name : ""}
          value={formData.productTypes}
          onChange={(e, newValue) =>
            setFormData((prev) => ({
              ...prev,
              productTypes: newValue,
            }))
          }
          isOptionEqualToValue={(option, value) =>
            option.id === value.id
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Product Types"
              placeholder="Select product types"
              margin="normal"
              fullWidth
            />
          )}
        />
      </Box>
      <Box>
        <Typography variant="h6">Child Collections</Typography>
        <ChildCollectionModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveChild}
          child={editingChild}
        />
        <Box component="ul" sx={{ pl: 2 }}>
          {(formData.children || []).map((child, index) => (
            <Box
              component="li"
              key={index}
              sx={{ mb: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography>{child.displayName}</Typography>
              <Button variant="outlined" size="small" onClick={() => handleEditChild(child, index)}>
                Edit
              </Button>
            </Box>
          ))}
        </Box>
        <Button variant="contained" onClick={handleAddChild} sx={{ mt: 2 }}>
          Add Child
        </Button>
      </Box>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
});

// Add display name to fix the linter warning
GenieCollectionsForm.displayName = "GenieCollectionsForm";

export default GenieCollectionsForm;
