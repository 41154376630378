export const getAPIAccessDetails = () => {
  const host = process.env.REACT_APP_DEV_HOST
    ? `${process.env.REACT_APP_DEV_HOST}`
    : location.hostname;
  const protocol = location.protocol;
  const qualifiedHost = `${protocol}//${host}`;
  return { qualifiedHost, headers: { } };
};

/**
 * makeApiRequest
 * @type {(options: {
 *  additionalHeaders?: Record<string, string>,
 * endpoint: string,
 * method: string,
 * body?: string | FormData | Blob | ArrayBufferView | ArrayBuffer | URLSearchParams | ReadableStream<Uint8Array> | null | undefined,
 * }) => Promise<any>}
 */
export const makeApiRequest = async ({
  additionalHeaders,
  endpoint,
  method,
  body,
  json,
}) => {
  const { qualifiedHost, headers } = getAPIAccessDetails();

  if (json) {
    Object.assign(headers, {
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  }
  const response = await fetch(new URL(endpoint, qualifiedHost), {
    headers: { ...headers, ...additionalHeaders },
    method,
    body: json ? JSON.stringify(body) : body,
  });

  if (!response.ok) {
    let message = response.statusText;
    let errorData = null;
    try {
      const errorResp = await response.json();
      errorData = errorResp.error ?? errorResp.message ?? response.statusText;
      message = typeof errorData === "string" ? errorData : response.statusText;
    } catch (err) {
      message = response.statusText;
    }

    const error = new Error(message);
    if (errorData) {
      error.data = errorData;
    }
    throw error;
  }

  return response.json();
};
