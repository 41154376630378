import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIngredientsUpload } from "../hooks";
import { ingredientGlossaryColumns } from "../../../constants/ingredientGlossaryConstants";

export const Ingredients = () => {
  const [errorMessage, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const {
    mutateAsync: uploadIngredients,
    isLoading,
    isError,
    error,
  } = useIngredientsUpload();
  const onCVSUpload = async (e) => {
    e.preventDefault();
    setError(null);
    const file = e.target.elements[0].files[0];
    const formData = await file.text();
    try {
      const resp = await uploadIngredients({ ingredientsCSV: formData });
      setStatusMessage(`Success: ${resp.message}`);
    } catch (error) {
      if (error.response) {
        setError(`Error: ${error.response.data.error}`);
      } else {
        setError(`Error: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    if (isError) {
      setError(`Error: ${error.message}`);
    }
  }, [isError, error]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ pt: 4, mx: 4, flex: 1 }}>
        <h3>Ingredients Uploader</h3>
        <p>
          This page is for uploading CSV files of ingredients to our glossary.
          The CSV file must have a header row with the following columns:
        </p>
        <ul>
          <li>
            <strong>ingredient</strong> - string
          </li>
          {ingredientGlossaryColumns
            .filter((col) => !["id", "ingredient"].includes(col.field))
            .map((col) => (
              <li key={col.field}>
                <strong>{col.field}</strong> - boolean
              </li>
            ))}
        </ul>
        {isLoading && <p>Uploading...</p>}
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        {statusMessage && <p style={{ color: "green" }}>{statusMessage}</p>}
        <form onSubmit={onCVSUpload}>
          <TextField type="file" inputProps={{ accept: ".csv" }} />
          <Button type="submit">Upload</Button>
        </form>
      </Box>
    </Box>
  );
};
