/* eslint-disable no-console */

class RegexUtils {
  static regexBuilder({ main_string, pattern_start, pattern_end, no_match_strings }) {
    const patternStart = pattern_start || "(\\W|^)";
    const patternEnd = pattern_end || "";
    let pattern = `^(?=.*${patternStart}${main_string}${patternEnd})`;

    if (no_match_strings && no_match_strings.length > 0) {
      pattern += `(?!.*(${no_match_strings.join("|")}))`;
    }

    pattern += ".*";
    return pattern;
  }

  static cleanUpMemos(txns) {
    let memos = txns.map(txn => txn.memo.toLowerCase());
    const stringsToRemove = ["(\\W|^)pos\\W", "point of sale", "billca", "\\/\\*bil+",
      "billcom", "purchase", "payment", "card", "transaction",
      "visa", "mastercard", "amex", "discover", "debit", "credit"];

    for (const stringToRemove of stringsToRemove) {
      memos = memos.map(memo => memo.replace(new RegExp(stringToRemove, "gi"), ""));
    }

    memos = memos.map(memo => memo.replace(/r[0-9]+|[0-9.]{2,}|(\w)\1{3,}|\/|:/g, ""));
    memos = memos.map(memo => memo.replace(/\*|-/g, " "));
    memos = memos.map(memo => memo.replace(/\s+/g, " "));
    memos = memos.map(memo => memo.trim());

    return [...new Set(memos)];
  }

  static sqlSingleLine(sqlQuery) {
    sqlQuery = sqlQuery.replace(/\n/g, " ");
    sqlQuery = sqlQuery.replace(/\s{2,}/g, " ");
    sqlQuery = sqlQuery.replace(/^\s+|\s+$/g, "");
    return sqlQuery;
  }
}

module.exports = RegexUtils;
