import React, { useCallback, useState } from "react";
import { Box, List, ListItem, ListItemText, Typography, TextField } from "@mui/material";
import { useCollections } from "./hooks";

const GenieCollectionsList = React.memo(({ onSelect, selectedId }) => {
  const { data: collections, isLoading, error } = useCollections();
  const [filterText, setFilterText] = useState("");

  const handleSelect = useCallback((collection) => {
    onSelect(collection);
  }, [onSelect]);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const filteredCollections = collections ? collections.filter(collection =>
    collection.displayName.toLowerCase().includes(filterText.toLowerCase())
  ) : [];

  if (isLoading) { return <Typography>Loading collections...</Typography>; }
  if (error) { return <Typography>Error loading collections: {error.message}</Typography>; }

  return (
    <Box
      sx={{
        width: "20%",
        maxHeight: "80vh",
        overflowY: "auto",
        borderRight: "1px solid #ddd",
        p: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Collections List
      </Typography>
      <TextField
        fullWidth
        size="small"
        placeholder="Filter collections..."
        variant="outlined"
        value={filterText}
        onChange={handleFilterChange}
        sx={{ mb: 2 }}
      />
      <List>
        {filteredCollections.map((collection) => (
          <ListItem
            key={collection.id}
            button
            onClick={() => handleSelect(collection)}
            selected={selectedId && parseInt(selectedId) === collection.id}
            sx={{
              backgroundColor: selectedId && parseInt(selectedId) === collection.id ? "rgba(0, 0, 0, 0.04)" : "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)"
              }
            }}
          >
            <ListItemText primary={collection.displayName} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
});

GenieCollectionsList.displayName = "GenieCollectionsList";

export default GenieCollectionsList;
