import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, CircularProgress, TextField, Button, Snackbar, Alert, Table, TableHead, TableRow, TableBody, Link } from "@mui/material";
import { StyledTableCell } from "../table";
import { useParams } from "react-router-dom";
import { useBrandQuery, useUpdateVendorMeta, useCrawledPagesQuery, useStartBrandCrawl } from "./hooks";

export default function BrandDetail() {
  const { id } = useParams();
  const { data, isLoading } = useBrandQuery(id);
  const { data: crawledPages, isLoading: isCrawledPagesLoading } = useCrawledPagesQuery(id);
  const updateVendorMeta = useUpdateVendorMeta(id);
  const startBrandCrawl = useStartBrandCrawl(id);
  const [vendorMeta, setVendorMeta] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    if (data?.brand?.Vendor?.VendorMeta) {
      // Only set VendorMeta fields that have corresponding TextField components
      const formFields = ["description", "websiteURL"];
      const filteredMeta = Object.fromEntries(
        Object.entries(data.brand.Vendor.VendorMeta)
          .filter(([key]) => formFields.includes(key))
      );
      setVendorMeta(filteredMeta);
    }
  }, [data]);

  const handleChange = (field) => (event) => {
    setVendorMeta({ ...vendorMeta, [field]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateVendorMeta.mutateAsync(vendorMeta);
      setSnackbar({
        open: true,
        message: "Vendor meta updated successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update vendor meta",
        severity: "error",
      });
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (!data.brand) {
    return (
      <Box m={4}>
        <Typography variant="h2">Brand not found</Typography>
      </Box>
    );
  }

  return (
    <Box m={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            {data.brand.Vendor.vendorName}
          </Typography>
          <Typography variant="h4" gutterBottom>
            <Link href={`/vendors/${data.brand.vendorId}`} style={{ textDecoration: "underline", color: "#1976d2" }}>
              Vendor ID: {data.brand.vendorId}
            </Link>
          </Typography>
          <Box mt={4}>
            <Typography variant="h3" gutterBottom>
              Brand Details
            </Typography>
            <Typography>Brand ID: {data.brand.id}</Typography>
            <Typography>Sustainability Status: {data.brand.sustainabilityStatus}</Typography>
            <Typography>Review Status: {data.brand.reviewStatus}</Typography>
            <Typography>Rubric Score: {data.brand.rubricScore?.score}</Typography>
            <Typography>
              Published Date: {data.brand.firstPublishedAt ? new Date(data.brand.firstPublishedAt).toLocaleDateString() : "-"}
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h3" gutterBottom>
              Vendor Meta
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  label="Website URL"
                  value={vendorMeta.websiteURL || ""}
                  onChange={handleChange("websiteURL")}
                  fullWidth
                  sx={{ maxWidth: 500 }}
                />
                <TextField
                  label="Description"
                  value={vendorMeta.description || ""}
                  onChange={handleChange("description")}
                  fullWidth
                  multiline
                  rows={4}
                  sx={{ maxWidth: 500 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  disabled={updateVendorMeta.isPending}
                  sx={{ width: "fit-content" }}
                >
                  {updateVendorMeta.isPending ? "Updating..." : "Update"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Crawling Data
          </Typography>
          <Box mt={4}>
            {isCrawledPagesLoading ? (
              <Box mb={2}>
                <Typography>Loading crawled pages...</Typography>
              </Box>
            ) : crawledPages?.crawledPages?.length > 0 ? (
              <Table sx={{ width: "fit-content" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Site Page</StyledTableCell>
                    <StyledTableCell>Sust Content?</StyledTableCell>
                    <StyledTableCell>Crawled At</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {crawledPages.crawledPages?.map((page) => (
                    <TableRow key={page._id}>
                      <StyledTableCell>
                        <a href={page.metadata.url} target="_blank" rel="noopener noreferrer">
                          {page.metadata.title?.substring(0, 40)}
                        </a>
                      </StyledTableCell>
                      <StyledTableCell>
                        <a href={page.htmlUrl} target="_blank" rel="noopener noreferrer">
                          {page.hasAiIdentifiedSustainabilityContent ? "Yes" : "No"}
                        </a>
                      </StyledTableCell>
                      <StyledTableCell>{new Date(page.crawledAt).toLocaleString([], {
                        month: "numeric",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                      })}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <>
                <Typography>No crawled pages found for this brand.</Typography>
                <Button
                  variant="contained"
                  onClick={async () => {
                    try {
                      await startBrandCrawl.mutateAsync();
                      setSnackbar({
                        open: true,
                        message: "Crawl started successfully",
                        severity: "success",
                      });
                    } catch (error) {
                      setSnackbar({
                        open: true,
                        message: error.message || "Failed to start crawl",
                        severity: "error",
                      });
                    }
                  }}
                  disabled={startBrandCrawl.isPending}
                  sx={{ mt: 2 }}
                >
                  {startBrandCrawl.isPending ? "Starting Crawl..." : "Start Crawl"}
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
