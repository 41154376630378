import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "components/utils";

const fetchCollections = async () => {
  const data = await makeApiRequest({
    endpoint: "/api/geniecollections",
    method: "GET"
  });
  return data.collections;
};

export const useCollections = () => {
  return useQuery(["genieCollections"], fetchCollections);
};

const createCollection = async (newCollection) => {
  const data = await makeApiRequest({
    endpoint: "/api/geniecollections",
    method: "POST",
    body: JSON.stringify(newCollection),
    additionalHeaders: {
      "Content-Type": "application/json"
    }
  });
  return data.collection;
};

export const useCreateCollection = () => {
  const queryClient = useQueryClient();
  return useMutation(createCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries(["genieCollections"]);
    },
  });
};

const updateCollection = async ({ id, updates }) => {
  const data = await makeApiRequest({
    endpoint: `/api/geniecollections/${id}`,
    method: "PUT",
    body: JSON.stringify(updates),
    additionalHeaders: {
      "Content-Type": "application/json"
    }
  });
  return data.collection;
};

export const useUpdateCollection = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries(["genieCollections"]);
    },
  });
};

const deleteCollection = async (id) => {
  await makeApiRequest({
    endpoint: `/api/geniecollections/${id}`,
    method: "DELETE"
  });
};

export const useDeleteCollection = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries(["genieCollections"]);
    },
  });
};

const fetchGenieCollection = async (id) => {
  const data = await makeApiRequest({
    endpoint: `/api/geniecollections/${id}`,
    method: "GET"
  });
  return data.collection;
};

export const useGenieCollection = (id) => {
  return useQuery(["genieCollection", id], () => fetchGenieCollection(id), {
    enabled: !!id,
  });
};
