import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../utils";
import { VALID_VENDOR_SEARCH_JOB_NAMES } from "../../constants/queueConstants";

export const useBrandQuery = (brandId) => {
  return useQuery({
    queryKey: ["brand", brandId],
    queryFn: async () => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}`,
        method: "GET",
      });
      return response;
    },
    keepPreviousData: true,
  });
};

export const useCrawledPagesQuery = (brandId) => {
  return useQuery({
    queryKey: ["crawledPages", brandId],
    queryFn: async () => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}/crawled-pages`,
        method: "GET",
      });
      return response;
    },
  });
};

export const useUpdateVendorMeta = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (updates) => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}/vendor-meta`,
        method: "PUT",
        body: updates,
        json: true,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["brand", brandId] });
    },
  });
};

export const useStartBrandCrawl = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}/start-crawl`,
        method: "POST",
        body: {},
        json: true
      });
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["crawledPages", brandId] });
    },
  });
};

export const useStartVendorRegexSearch = () => {
  return useMutation({
    mutationFn: async ({ brandName, pattern }) => {
      const response = await makeApiRequest({
        endpoint: "/api/vendors/start-vendor-job",
        method: "POST",
        body: {
          jobData: {
            jobName: VALID_VENDOR_SEARCH_JOB_NAMES.START_VENDOR_REGEX_SEARCH,
            brandName,
            pattern,
          },
        },
        json: true,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    }
  });
};

export const useGetMatchedVendors = (brandName) => {
  return useQuery({
    queryKey: ["matchedVendors", brandName],
    queryFn: async () => {
      const response = await makeApiRequest({
        endpoint: "/api/vendors/matched-vendors?brandName=" + brandName,
        method: "GET",
      });
      return response;
    },
  });
};

export const useCreateBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (brandData) => {
      const response = await makeApiRequest({
        endpoint: "/api/brands",
        method: "POST",
        body: brandData,
        json: true,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["brands"] });
    },
  });
};
