import React, { useState, useEffect, useCallback } from "react";
import { Modal, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useCollections } from "./hooks";

const ChildCollectionModal = React.memo(({ isOpen, onClose, onSave, child }) => {
  const { data: collections, isLoading } = useCollections();
  const [formData, setFormData] = useState({
    slug: "",
    displayName: "",
    searchOptions: "",
    searchModels: "",
    childGenieCollectionId: "",
    id: ""
  });
  const [errors, setErrors] = useState({});

  // Update form data when child prop changes or modal opens
  useEffect(() => {
    if (child) {
      setFormData({
        slug: child.slug || "",
        displayName: child.displayName || "",
        searchOptions: typeof child.searchOptions === "object"
          ? JSON.stringify(child.searchOptions)
          : child.searchOptions || "",
        searchModels: child.searchModels || "",
        childGenieCollectionId: child.childGenieCollectionId ? Number(child.childGenieCollectionId) : "",
        id: child.id ? Number(child.id) : ""
      });
      setErrors({});
    } else if (!isOpen) {
      // Reset form when modal closes
      setFormData({
        slug: "",
        displayName: "",
        searchOptions: "",
        searchModels: "",
        childGenieCollectionId: "",
        id: ""
      });
      setErrors({});
    }
  }, [child, isOpen]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    // Convert numeric fields to numbers
    if (name === "childGenieCollectionId" || name === "id") {
      const numValue = value === "" ? "" : Number(value);
      setFormData((prev) => ({ ...prev, [name]: numValue }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    // Clear error for the field being changed
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!formData.displayName.trim()) {
      newErrors.displayName = "Display Name is required";
    }

    if (formData.childGenieCollectionId === "") {
      newErrors.childGenieCollectionId = "Child Collection is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleSave = useCallback(() => {
    // Validate form before saving
    if (!validateForm()) {
      return;
    }

    const processedFormData = {
      ...formData,
      childGenieCollectionId: formData.childGenieCollectionId === "" ? null : Number(formData.childGenieCollectionId),
      ...(formData.id === "" ? {} : { id: Number(formData.id) }),
      searchOptions: formData.searchOptions && typeof formData.searchOptions === "string" &&
        formData.searchOptions.trim().startsWith("{") ?
        JSON.parse(formData.searchOptions) : formData.searchOptions,
    };

    onSave(processedFormData);
    onClose();
  }, [formData, onSave, onClose, validateForm]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <h2>{child ? "Edit Child Collection" : "Add Child Collection"}</h2>
        <TextField
          fullWidth
          margin="normal"
          label="Slug"
          name="slug"
          value={formData.slug}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Display Name *"
          name="displayName"
          value={formData.displayName}
          onChange={handleChange}
          error={!!errors.displayName}
          helperText={errors.displayName}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Search Options"
          name="searchOptions"
          value={formData.searchOptions}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Search Models"
          name="searchModels"
          value={formData.searchModels}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal" error={!!errors.childGenieCollectionId} required>
          <InputLabel id="child-collection-select-label">Child Collection *</InputLabel>
          <Select
            labelId="child-collection-select-label"
            id="child-collection-select"
            name="childGenieCollectionId"
            value={formData.childGenieCollectionId}
            label="Child Collection *"
            onChange={handleChange}
            disabled={isLoading}
          >
            {(collections || []).map((collection) => (
              <MenuItem key={collection.id} value={Number(collection.id)}>
                {collection.displayName}
              </MenuItem>
            ))}
          </Select>
          {errors.childGenieCollectionId && (
            <Box component="span" sx={{ color: "error.main", fontSize: "0.75rem", mt: 0.5, ml: 1.75 }}>
              {errors.childGenieCollectionId}
            </Box>
          )}
        </FormControl>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

// Add display name to fix the linter warning
ChildCollectionModal.displayName = "ChildCollectionModal";

export default ChildCollectionModal;
