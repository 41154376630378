const Rubrics = {
  CHEMICAL: "chemical",
  TEXTILE: "textile",
};

const RubricQuestionKeys = {
  Parabens: "Parabens",
  Phthalates: "Phthalates",
  PFAS: "PFAS",
  Petrochemical: "Petrochemical",
  PalmOil: "PalmOil",
  Ambiguous: "Ambiguous",
  MayBePetrochemical: "MayBePetrochemical",
  MayBePalmOil: "MayBePalmOil",
  Microplastics: "Microplastics",
  HarmfulSuncare: "HarmfulSuncare",
  OtherConcern: "OtherConcern"
};

const RubricQuestions = {
  [RubricQuestionKeys.Parabens]: {
    id: 1,
    question: "Contains parabens?",
    column: "isParaben",
    color: "#8AFFC1"
  },
  [RubricQuestionKeys.Phthalates]: {
    id: 2,
    question: "Contains phthalates?",
    column: "isPhthalates",
    color: "#FACC6B"
  },
  [RubricQuestionKeys.PFAS]: {
    id: 3,
    question: "Contains PFAS?",
    column: "isPFAS",
    color: "#84BCDA"
  },
  [RubricQuestionKeys.Petrochemical]: {
    id: 4,
    question: "Contains petrochemical?",
    column: "isPetrochemical",
    color: "#FF715B"
  },
  [RubricQuestionKeys.PalmOil]: {
    id: 5,
    question: "Contains palm oil?",
    column: "isPalmOil",
    color: "#EFC3F5"
  },
  [RubricQuestionKeys.Ambiguous]: {
    id: 6,
    question: "Is ambiguous?",
    column: "isAmbiguous",
    flagForFurtherReview: true,
    color: "#A5AE9E"
  },
  [RubricQuestionKeys.MayBePetrochemical]: {
    id: 7,
    question: "May be petrochemical derived?",
    column: "mayBePetrochemicalDerived",
    flagForFurtherReview: true,
    color: "#D4F4DD"
  },
  [RubricQuestionKeys.MayBePalmOil]: {
    id: 8,
    question: "May be palm oil derived?",
    column: "mayBePalmOilDerived",
    flagForFurtherReview: true,
    color: "#E8D2AE"
  },
  [RubricQuestionKeys.Microplastics]: {
    id: 9,
    question: "Is microplastics?",
    column: "isMicroplastics",
    color: "#cce3ca"
  },
  [RubricQuestionKeys.HarmfulSuncare]: {
    id: 10,
    question: "Is harmful suncare?",
    column: "isHarmfulSuncare",
    color: "#e3d7ca"
  },
  [RubricQuestionKeys.OtherConcern]: {
    id: 11,
    question: "Has other concern?",
    column: "hasOtherConcern",
    flagForFurtherReview: true,
    color: "#e3b6c3"
  }
};

const RubricsChecks = {
  [Rubrics.CHEMICAL]: [
    RubricQuestions[RubricQuestionKeys.Parabens],
    RubricQuestions[RubricQuestionKeys.Phthalates],
    RubricQuestions[RubricQuestionKeys.Petrochemical],
    RubricQuestions[RubricQuestionKeys.PFAS],
    RubricQuestions[RubricQuestionKeys.PalmOil],
    RubricQuestions[RubricQuestionKeys.Ambiguous],
    RubricQuestions[RubricQuestionKeys.MayBePetrochemical],
    RubricQuestions[RubricQuestionKeys.MayBePalmOil],
    RubricQuestions[RubricQuestionKeys.Microplastics],
    RubricQuestions[RubricQuestionKeys.HarmfulSuncare],
    RubricQuestions[RubricQuestionKeys.OtherConcern],
  ],
};

const ingredientGlossaryColumns = [
  { field: "id", headerName: "ID", width: 60 },
  { field: "ingredient", headerName: "Ingredient", width: 400 },
  { field: "isCaseSensitive", headerName: "isCaseSensitive", width: 125 },
  { field: "isParaben", headerName: "isParaben", width: 125 },
  { field: "isPhthalates", headerName: "isPhthalates", width: 125 },
  { field: "isPFAS", headerName: "isPFAS", width: 125 },
  { field: "isPetrochemical", headerName: "isPetrochemical", width: 125 },
  { field: "isPalmOil", headerName: "isPalmOil", width: 125 },
  { field: "hasNoIssue", headerName: "hasNoIssue", width: 125 },
  { field: "isAmbiguous", headerName: "isAmbiguous", width: 125 },
  {
    field: "mayBePetrochemicalDerived",
    headerName: "mayBePetrochemicalDerived",
    width: 125,
  },
  {
    field: "mayBePalmOilDerived",
    headerName: "mayBePalmOilDerived",
    width: 125,
  },
  { field: "isMicroplastics", headerName: "isMicroplastics", width: 125 },
  { field: "isHarmfulSuncare", headerName: "isHarmfulSuncare", width: 125 },
  { field: "hasOtherConcern", headerName: "hasOtherConcern", width: 125 },
];


module.exports = {
  Rubrics,
  RubricsChecks,
  RubricQuestionKeys,
  RubricQuestions,
  ingredientGlossaryColumns
};
