import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "components/utils";

export const useVendorEmailsUpload = () => {
  return useMutation(async ({ emailsCSV }) => {
    return makeApiRequest({
      endpoint: "/api/vendors/vendor-emails",
      method: "POST",
      body: JSON.stringify({ emailsCSV }),
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  });
};

export const useProductTypesUpload = () => {
  return useMutation(async ({ productTypesCSV }) => {
    return makeApiRequest({
      endpoint: "/api/producttypes/csvupload",
      method: "POST",
      body: JSON.stringify({ productTypesCSV }),
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  });
};


export const useIngredientsUpload = () => {
  return useMutation(async ({ ingredientsCSV }) => {
    return makeApiRequest({
      endpoint: "/api/ingredient_glossary/csvupload",
      method: "POST",
      body: JSON.stringify({ ingredientsCSV }),
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  });
};
