import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import GenieCollectionsList from "./GenieCollectionsList";
import GenieCollectionsForm from "./GenieCollectionsForm";
import { useGenieCollection } from "./hooks";

const GenieCollections = () => {
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const [selectedCollection, setSelectedCollection] = useState(null);
  const { data: collectionData, isLoading } = useGenieCollection(collectionId);

  useEffect(() => {
    if (!collectionId) {
      setSelectedCollection(null);
    } else if (collectionData && !isLoading) {
      setSelectedCollection(collectionData);
    }
  }, [collectionData, isLoading, collectionId]);

  const handleCollectionSelect = useCallback((collection) => {
    setSelectedCollection(collection);
    navigate(`/genie/collections/${collection.id}`);
  }, [navigate]);

  const handleSaveComplete = useCallback((newId) => {
    navigate(`/genie/collections/${newId}`);
  }, [navigate]);

  return (
    <Box display="flex" flexDirection="row" height="100vh">
      <GenieCollectionsList
        onSelect={handleCollectionSelect}
        selectedId={collectionId ? parseInt(collectionId) : null}
      />
      <Box flex={1} p={3}>
        <GenieCollectionsForm
          collection={selectedCollection}
          onSave={handleSaveComplete}
        />
      </Box>
    </Box>
  );
};

export default GenieCollections;
