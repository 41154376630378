import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Snackbar, Alert, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Paper } from "@mui/material";
import { useCreateBrand, useStartVendorRegexSearch, useGetMatchedVendors } from "./hooks";
import { useNavigate } from "react-router-dom";
import RegexUtils from "../../services/matchingUtils/regex";

export default function BrandCreate() {
  const createBrand = useCreateBrand();
  const startVendorRegexSearch = useStartVendorRegexSearch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    vendorId: "",
    regexStart: "",
    regexMatch: "",
    regexEnd: "",
    negativeTerms: ""
  });
  const [matchedVendors, setMatchedVendors] = useState([]);
  const [isTestingRegex, setIsTestingRegex] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const { data: matchedVendorsData, isLoading: isLoadingMatchedVendors, refetch } = useGetMatchedVendors(formData.name);

  useEffect(() => {
    if (matchedVendorsData?.error === "No matching vendors found") {
      setMatchedVendors([]);
    } else if (matchedVendorsData?.matchedVendors) {
      setMatchedVendors(matchedVendorsData.matchedVendors.map(vendor => ({
        id: vendor.vendorId,
        vendorName: vendor.vendorName,
        joroSubcategory: vendor.joroSubcategory,
        joroSubcategoryId: vendor.joroSubcategoryId,
        transactionCount: vendor.transactionCount,
        isCleanVendorName: vendor.isCleanVendorName,
        correctedVendorId: vendor.correctedVendorId,
      })));
    }
  }, [matchedVendorsData]);

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSelectVendor = (vendorId) => {
    setFormData({ ...formData, vendorId });
  };

  const handleTestRegex = async (event) => {
    event.preventDefault();
    setIsTestingRegex(true);
    setMatchedVendors([]);
    const brandName = formData.name;
    if (!brandName) {
      setSnackbar({
        open: true,
        message: "Please fill in Brand Name",
        severity: "error"
      });
      setIsTestingRegex(false);
      return;
    }
    const regexPattern = RegexUtils.regexBuilder({
      main_string: formData.regexMatch,
      pattern_start: formData.regexStart || undefined,
      pattern_end: formData.regexEnd || undefined,
      no_match_strings: formData.negativeTerms ? formData.negativeTerms.split(",").map(term => term.trim()) : undefined
    });

    setFormData({ ...formData, regexPattern });

    const response = await startVendorRegexSearch.mutateAsync({ brandName, pattern: regexPattern });
    if (response.error) {
      setSnackbar({
        open: true,
        message: response.error,
        severity: "error"
      });
    } else {
      await refetch();
      setSnackbar({
        open: true,
        message: "Vendor regex search job started successfully. Checking for matches...",
        severity: "success",
      });
    }
    setIsTestingRegex(false);
  };

  const handleAddNewBrand = async (event) => {
    event.preventDefault();
    if (!formData.name) {
      setSnackbar({
        open: true,
        message: "Please fill in Brand Name",
        severity: "error"
      });
      return;
    }
    try {
      const brandData = {
        name: formData.name,
        vendorId: formData.vendorId,
        regexPattern: formData.regexPattern,
      };

      const response = await createBrand.mutateAsync(brandData);

      setSnackbar({
        open: true,
        message: "Brand created successfully",
        severity: "success",
      });

      if (response?.brand?.id) {
        navigate(`/brands/${response.brand.id}`);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : "Failed to create brand",
        severity: "error",
      });
    }
  };

  return (
    <Box m={4}>
      <Typography variant="h2" gutterBottom>
        Create New Brand
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 4, maxWidth: 800 }}>
        <form onSubmit={handleAddNewBrand}>
          <Box mb={4} display="flex" flexDirection="column" gap={3}>
            <TextField
              label="Brand Name"
              value={formData.name}
              onChange={handleChange("name")}
              fullWidth
              required
            />

            <TextField
              label="Vendor ID (if empty, new vendor will be created)"
              name="vendorId"
              value={formData.vendorId}
              onChange={handleChange("vendorId")}
              fullWidth
            />

            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ height: 56 }}
              >
                Add new brand
              </Button>
            </Box>
          </Box>
        </form>

        <form onSubmit={handleTestRegex}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h3" gutterBottom>
              Regex Pattern
            </Typography>

            <TextField
              label="Generated Regex Pattern"
              value={formData.regexPattern || ""}
              disabled
              fullWidth
              sx={{ mt: 2 }}
            />

            <TextField
              label="Regex start"
              value={formData.regexStart}
              onChange={handleChange("regexStart")}
              fullWidth
            />

            <TextField
              label="Regex to match"
              value={formData.regexMatch}
              onChange={handleChange("regexMatch")}
              fullWidth
              required
            />

            <TextField
              label="Regex end"
              value={formData.regexEnd}
              onChange={handleChange("regexEnd")}
              fullWidth
            />

            <TextField
              label="Negative terms (comma separated)"
              value={formData.negativeTerms}
              onChange={handleChange("negativeTerms")}
              fullWidth
              helperText="Enter terms that should exclude vendors from matching, separated by commas"
            />

            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isTestingRegex}
                sx={{ height: 56 }}
              >
                {isTestingRegex ? <CircularProgress size={24} /> : "Try this regex"}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>

      {isLoadingMatchedVendors ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : matchedVendors.length > 0 ? (
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            Select a vendor to connect to this brand
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select vendor</TableCell>
                <TableCell>Vendor ID</TableCell>
                <TableCell>Vendor Name</TableCell>
                <TableCell>Subcategory</TableCell>
                <TableCell>Txn count</TableCell>
                <TableCell>Clean vendor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matchedVendors.map((vendor) => (
                <TableRow key={`${vendor.id}-${vendor.name}`}>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleSelectVendor(vendor.id)}
                    >
                      Select
                    </Button>

                  </TableCell>
                  <TableCell>{vendor.id}</TableCell>
                  <TableCell>{vendor.vendorName}</TableCell>
                  <TableCell>{vendor.joroSubcategory}</TableCell>
                  <TableCell>{vendor.transactionCount}</TableCell>
                  <TableCell>
                    {vendor.isCleanVendorName ? (
                      "yes"
                    ) : (
                      "no"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            No vendors matched
          </Typography>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
