import React, { useState } from "react";
import {
  Typography,
  TextareaAutosize,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
} from "@mui/material";
import { useIngredientMatchResults } from "../hooks";
import {
  Rubrics,
  RubricsChecks,
} from "../../../../constants/ingredientGlossaryConstants";
import { PageHeader, PageLayout, PageMain } from "components/layout/PageLayout";

const highlightMatches = (text, matchList) => {
  if (!matchList?.length) {
    return text;
  }

  const matches = matchList
    .filter((m) => typeof m.start === "number" && typeof m.end === "number")
    .sort((a, b) => a.start - b.start);

  const parts = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    const { start, end, color } = match;

    if (start < lastIndex) {
      // eslint-disable-next-line
      console.warn("Skipping overlapping match:", match);
      return;
    }

    if (start > lastIndex) {
      parts.push(<span key={`text-${i}`}>{text.slice(lastIndex, start)}</span>);
    }

    // highlighted part
    parts.push(
      <mark key={`mark-${i}`} style={{ backgroundColor: color }}>
        {text.slice(start, end)}
      </mark>
    );

    // move pointer
    lastIndex = end;
  });

  if (lastIndex < text.length) {
    parts.push(<span key="final">{text.slice(lastIndex)}</span>);
  }

  return parts;
};

const HighlightedText = ({ text, results }) => {
  const allMatches = (results ?? [])
    .filter((r) => r.id !== "unmatched")
    .flatMap((r) => r.matches || []);

  return <div>{highlightMatches(text, allMatches)}</div>;
};

const UnmatchedTextList = ({ results }) => {
  const unmatched = results?.find((r) => r.id === "unmatched");

  if (!unmatched || !unmatched.matches?.length) {
    return <p>No unmatched text.</p>;
  }

  return (
    <ul>
      {unmatched.matches.map((m, idx) => (
        <li key={idx}>{m.matchedText[0]}</li>
      ))}
    </ul>
  );
};

export const IngredientChecker = () => {
  const [selectedRubric, setSelectedRubric] = useState("");
  const [textToCheck, setTextToCheck] = useState("");
  const { data } = useIngredientMatchResults({
    rubric: selectedRubric,
    text: textToCheck,
  });

  return (
    <PageLayout>
      <PageHeader>
        <Typography variant="h3">Ingredient/Materials Checker</Typography>
      </PageHeader>

      <PageMain>
        <Box display="flex" flexDirection="row">
          <Box sx={{ width: "40vw", mr: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Step 1. Select Rubric
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedRubric}
                label="Rubric"
                onChange={(e) => setSelectedRubric(e.target.value)}
              >
                {Object.values(Rubrics).map((rubric) => {
                  const menuItemCopy = rubric.toUpperCase();
                  let menuItemCopyAppend = "";
                  let isDisabled = false;
                  if (!RubricsChecks[rubric]) {
                    isDisabled = true;
                    menuItemCopyAppend = " (coming soon!)";
                  }
                  return (
                    <MenuItem key={rubric} value={rubric} disabled={isDisabled}>
                      {menuItemCopy}
                      {menuItemCopyAppend}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {selectedRubric ? (
              <FormControl fullWidth>
                <TextareaAutosize
                  onChange={(e) => setTextToCheck(e.target.value)}
                  value={textToCheck}
                  aria-label="empty textarea"
                  minRows={10}
                  width={"40vw"}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 1,
                  }}
                  labelId="demo-simple-input-label"
                  id="demo-simple-input"
                  placeholder="Step 2. Paste ingredient or materials list here"
                />
              </FormControl>
            ) : null}
            {data?.results ? (
              <>
                <Box mt={2}>
                  <Typography variant="body2" gutterBottom>
                    Step 4. Review color-coded matches to rubric questions
                    below:
                  </Typography>
                  <Box
                    sx={{
                      whiteSpace: "pre-wrap",
                      background: "#f5f5f5",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: 1,
                      padding: 1,
                      minHeight: "120px",
                    }}
                  >
                    <HighlightedText
                      text={textToCheck}
                      results={data?.results}
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography variant="body2" gutterBottom>
                    Step 5: Review potential additions to ingredient glossary:
                  </Typography>
                  <Box
                    sx={{
                      whiteSpace: "pre-wrap",
                      background: "#f5f5f5",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: 1,
                      padding: 1,
                      minHeight: "120px",
                    }}
                  >
                    <UnmatchedTextList
                      text={textToCheck}
                      results={data?.results}
                    />
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
          {data?.results ? (
            <Box sx={{ width: "30vw" }}>
              <Typography variant="h4">
                Step 3: Review relevant rubric questions
              </Typography>
              <Box
                component="ul"
                flex
                sx={{
                  width: "100%",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: 1,
                }}
              >
                {data?.results?.filter((r) => r.id !== "unmatched").map((result) => (
                  <Grid
                    container
                    component="li"
                    key={result.id}
                    flex
                    flexDirection="column"
                    justifyContent="space-between"
                    flexWrap={"nowrap"}
                    sx={{
                      backgroundColor: result.answer ? result.color : "transparent",
                      padding: 1,
                    }}
                  >
                    <Grid
                      container
                      flex
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="body1">
                          {result.question}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {result.answer ? "yes" : "no"}
                        </Typography>
                      </Grid>
                    </Grid>
                    {!!result.flagForFurtherReview && !!result.answer ? (
                      <Grid
                        item
                        sx={{
                          color: "red",
                          fontWeight: 500,
                          padding: 1,
                        }}
                      >
                        <Typography variant="body3">
                          Further review is required
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                ))}
              </Box>
            </Box>
          ) : null}
        </Box>
      </PageMain>
    </PageLayout>
  );
};
